<template>
  <div class="onboarding-slide1">
    <img class="icon" :src="currencyIcon" />
    <div class="content" @click="$emit('next')">
      <p class="title">{{ currencyNameTranslated(this.$t) }}</p>
      <p
        v-t="{ path: 'components.onboarding.default.slide1.text' }"
        class="text"
      />
      <p
        v-t="{ path: 'components.onboarding.default.thanks' }"
        class="thanks"
        @click="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia';

export default {
  computed: {
    ...mapState(useMeStore, ['currencyIcon', 'currencyNameTranslated']),
  },
};
</script>

<style lang="scss" scoped>
.onboarding-slide1 {
  @apply px-4;
  height: 28rem;
}

.content {
  @apply bg-white px-6 shadow-box-lg -mt-12 pt-16 rounded-theme-small relative;
  height: 22rem;
  box-sizing: border-box;
}

.icon {
  @apply mx-auto h-24 z-20 relative;
}

.title {
  @apply text-theme-primary font-theme-title text-4xl text-center uppercase pb-4;
}

.text {
  @apply text-theme-primary text-xl text-center;
}

.thanks {
  @apply absolute text-theme-primary font-black text-base text-center bottom-0 left-0 right-0 pb-4 cursor-pointer w-full;
}
</style>
