<template>
  <div class="components-menu-container">
    <nuxt-link :to="{ name: 'me-rewards' }" class="menu-item">
      <img
        v-if="$route.name == 'me-rewards'"
        class="menu-image"
        src="~/assets/img/menu/rewards_active.png"
      />
      <img v-else class="menu-image" src="~/assets/img/menu/rewards.png" />
      <p class="menu-text">{{ $t('menu.rewards') }}</p>
    </nuxt-link>
    <nuxt-link
      v-if="special && specialValid"
      :to="{ name: 'me-special-slug', params: { slug: special.slug } }"
      class="menu-item"
    >
      <img
        v-if="specialRouteActive"
        class="menu-image"
        :src="specialIconActive"
      />
      <img v-else class="menu-image" :src="specialIcon" />
      <p class="menu-text">{{ specialText }}</p>
    </nuxt-link>
    <nuxt-link :to="{ name: 'me-challenges' }" class="menu-item">
      <img
        v-if="$route.name == 'me-challenges'"
        class="menu-image"
        src="~/assets/img/menu/challenges_active.png"
      />
      <img v-else class="menu-image" src="~/assets/img/menu/challenges.png" />
      <p class="menu-text">{{ $t('menu.challenges') }}</p>
    </nuxt-link>
    <nuxt-link :to="{ name: 'me-profile' }" class="menu-item">
      <img
        v-if="$route.name == 'me-profile'"
        class="menu-image"
        src="~/assets/img/menu/profile_active.png"
      />
      <img v-else class="menu-image" src="~/assets/img/menu/profile.png" />
      <p class="menu-text">{{ $t('menu.profile') }}</p>
    </nuxt-link>
  </div>
</template>

<script lang="ts">
export default defineNuxtComponent({
  setup() {
    const specialStore = useSpecialStore()
    const meStore = useMeStore()
    const route = useRoute()

    const special = computed(() => specialStore.getMenuSpecial)
    const lang = meStore.lang
    const specialText = computed(() => special.value?.menu_link?.[lang])
    const specialIcon = computed(() => {
      return special.value?.media.menu_icon?.[0]?.conversions?.default || null
    })
    const specialIconActive = computed(() => {
      return (
        special.value?.media.menu_icon_active?.[0]?.conversions?.default || null
      )
    })
    const specialValid = computed(
      () => specialIcon.value !== null && specialIconActive.value !== null,
    )

    const specialRouteActive = computed(() => {
      return (
        route.name === 'me-special-slug' &&
        route.params.slug === special.value?.slug
      )
    })

    return {
      special,
      specialText,
      specialIcon,
      specialIconActive,
      specialValid,
      specialRouteActive,
    }
  },
})
</script>

<style lang="scss" scoped>
.components-menu-container {
  @apply flex w-full justify-center text-sm fixed bottom-0 bg-theme-primary py-2 shadow-box-lg z-50;
  transform: translate3d(0, 0, 0); //iframe flicker fix
}

.menu-item {
  @apply w-1/4 text-center cursor-pointer;
}

.menu-image {
  @apply w-8 inline;
}

.menu-text {
  @apply text-white pt-1;
}
</style>
